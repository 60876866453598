import { HttpClient, HttpRequest } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MarketplaceService = /** @class */ (function () {
    function MarketplaceService(http) {
        this.http = http;
    }
    MarketplaceService.prototype.getPendingRegistrations = function () {
        return this.http.get(environment.api.url + "/admin/marketplace/registration/pending");
    };
    MarketplaceService.prototype.acceptRegistration = function (token) {
        return this.http.put(environment.api.url + "/admin/marketplace/registration/accept/" + token, {});
    };
    MarketplaceService.prototype.declineRegistration = function (token) {
        return this.http.put(environment.api.url + "/admin/marketplace/registration/decline/" + token, {});
    };
    MarketplaceService.prototype.getPendingRequests = function () {
        return this.http.get(environment.api.url + "/admin/marketplace/requests/pending");
    };
    MarketplaceService.prototype.downloadRequestDocument = function (documentId) {
        return this.http.get(environment.api.url + "/admin/marketplace/document/download/" + documentId, {
            responseType: 'arraybuffer'
        });
    };
    MarketplaceService.prototype.uploadBlurredDocument = function (token, files) {
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name);
        }
        var reqUrl = environment.api.url + "/admin/marketplace/request/upload/" + token;
        var request = new HttpRequest('POST', reqUrl, formData, {
            reportProgress: true
        });
        return this.http.request(request);
    };
    MarketplaceService.ngInjectableDef = i0.defineInjectable({ factory: function MarketplaceService_Factory() { return new MarketplaceService(i0.inject(i1.HttpClient)); }, token: MarketplaceService, providedIn: "root" });
    return MarketplaceService;
}());
export { MarketplaceService };

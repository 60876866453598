// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// 3rd-party
import firebase from 'firebase/app';
import 'firebase/remote-config';
import RemoteConfig = firebase.remoteConfig.RemoteConfig;
import { interval, Subscription } from 'rxjs';
// app
import { ProfileService, ProfileStoreService } from './services';
import { environment } from '../environments/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;
  maintenanceMode = false;
  remoteConfig: RemoteConfig | null = null;

  intervalSubscription: Subscription | null = null;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private profileStoreService: ProfileStoreService
  ) {}

  ngOnInit() {
    this.initializeFirebase();

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private initializeFirebase(): void {
    this.loading = true;
    const app = firebase.initializeApp(environment.firebase.config);
    this.remoteConfig = firebase.remoteConfig(app);

    this.remoteConfig.settings.minimumFetchIntervalMillis = 1;
    this.remoteConfig.settings.fetchTimeoutMillis = 60000;
    this.remoteConfig.defaultConfig = {
      [environment.firebase.maintenanceKey]: false
    };

    this.fetchAndActivateFirebaseRemoteConfig();
  }

  private callFirebaseEveryHour(): void {
    this.intervalSubscription = interval(3600000).subscribe(() => {
      this.remoteConfig.fetchAndActivate().then(
        () => {
          this.maintenanceMode = this.remoteConfig.getBoolean(environment.firebase.maintenanceKey);

          if (!this.maintenanceMode) {
            this.getProfile();
          }
        },
        () => {
          this.maintenanceMode = false;
        }
      );
    });
  }

  private fetchAndActivateFirebaseRemoteConfig(): void {
    this.remoteConfig.fetchAndActivate().then(
      () => {
        this.maintenanceMode = this.remoteConfig.getBoolean(environment.firebase.maintenanceKey);
        this.callFirebaseEveryHour();
        this.loading = false;

        if (!this.maintenanceMode) {
          this.getProfile();
        }
      },
      () => {
        this.maintenanceMode = false;
        this.callFirebaseEveryHour();
        this.loading = false;
      }
    );
  }

  private getProfile(): void {
    this.profileService.getProfile().subscribe(user => {
      this.profileStoreService.setProfileData(user);
      this.profileService.setProfile(user);
      localStorage.setItem('ROLE_ADMIN', user.roles.findIndex(role => role === 'ROLE_ADMIN') > 0 ? 'true' : 'false');
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}

// Angular
import { OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// 3rd-party
import firebase from 'firebase/app';
import 'firebase/remote-config';
import { interval } from 'rxjs';
// app
import { ProfileService, ProfileStoreService } from './services';
import { environment } from '../environments/environment';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, profileService, profileStoreService) {
        this.router = router;
        this.profileService = profileService;
        this.profileStoreService = profileStoreService;
        this.loading = false;
        this.maintenanceMode = false;
        this.remoteConfig = null;
        this.intervalSubscription = null;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.initializeFirebase();
        this.router.events.subscribe(function (evt) {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    };
    AppComponent.prototype.initializeFirebase = function () {
        var _a;
        this.loading = true;
        var app = firebase.initializeApp(environment.firebase.config);
        this.remoteConfig = firebase.remoteConfig(app);
        this.remoteConfig.settings.minimumFetchIntervalMillis = 1;
        this.remoteConfig.settings.fetchTimeoutMillis = 60000;
        this.remoteConfig.defaultConfig = (_a = {},
            _a[environment.firebase.maintenanceKey] = false,
            _a);
        this.fetchAndActivateFirebaseRemoteConfig();
    };
    AppComponent.prototype.callFirebaseEveryHour = function () {
        var _this = this;
        this.intervalSubscription = interval(3600000).subscribe(function () {
            _this.remoteConfig.fetchAndActivate().then(function () {
                _this.maintenanceMode = _this.remoteConfig.getBoolean(environment.firebase.maintenanceKey);
                if (!_this.maintenanceMode) {
                    _this.getProfile();
                }
            }, function () {
                _this.maintenanceMode = false;
            });
        });
    };
    AppComponent.prototype.fetchAndActivateFirebaseRemoteConfig = function () {
        var _this = this;
        this.remoteConfig.fetchAndActivate().then(function () {
            _this.maintenanceMode = _this.remoteConfig.getBoolean(environment.firebase.maintenanceKey);
            _this.callFirebaseEveryHour();
            _this.loading = false;
            if (!_this.maintenanceMode) {
                _this.getProfile();
            }
        }, function () {
            _this.maintenanceMode = false;
            _this.callFirebaseEveryHour();
            _this.loading = false;
        });
    };
    AppComponent.prototype.getProfile = function () {
        var _this = this;
        this.profileService.getProfile().subscribe(function (user) {
            _this.profileStoreService.setProfileData(user);
            _this.profileService.setProfile(user);
            localStorage.setItem('ROLE_ADMIN', user.roles.findIndex(function (role) { return role === 'ROLE_ADMIN'; }) > 0 ? 'true' : 'false');
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    };
    return AppComponent;
}());
export { AppComponent };

// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { VerificationToken } from '../../models';

@Injectable({ providedIn: 'root' })
export class MarketplaceService {
  constructor(private http: HttpClient) {}

  getPendingRegistrations(): Observable<VerificationToken[]> {
    return this.http.get<VerificationToken[]>(`${environment.api.url}/admin/marketplace/registration/pending`);
  }

  acceptRegistration(token: string): Observable<any> {
    return this.http.put(`${environment.api.url}/admin/marketplace/registration/accept/${token}`, {});
  }

  declineRegistration(token: string): Observable<any> {
    return this.http.put(`${environment.api.url}/admin/marketplace/registration/decline/${token}`, {});
  }

  getPendingRequests(): Observable<any> {
    return this.http.get<any>(`${environment.api.url}/admin/marketplace/requests/pending`);
  }

  downloadRequestDocument(documentId: string): Observable<any> {
    return this.http.get<any>(`${environment.api.url}/admin/marketplace/document/download/${documentId}`, {
      responseType: 'arraybuffer' as 'json'
    });
  }

  uploadBlurredDocument(token: string, files: File[]): Observable<any> {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].name);
    }
    const reqUrl = `${environment.api.url}/admin/marketplace/request/upload/${token}`;
    const request = new HttpRequest('POST', reqUrl, formData, {
      reportProgress: true
    });
    return this.http.request(request);
  }
}

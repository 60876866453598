<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form novalidate (submit)="onSubmit()" [formGroup]="loginFormGroup">
                  <h1>Login</h1>
                  <p class="text-muted">Login</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input formControlName="email" id="email" type="text" class="form-control" placeholder="Email" />
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input
                      formControlName="password"
                      id="password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                    />
                  </div>
                  <p *ngIf="errorLabel != ''" class="jasmine-errored text-danger">{{ errorLabel }}</p>
                  <div class="row">
                    <div class="col-6">
                      <!--<button type="button" class="btn btn-link px-0">Forgot password?</button>-->
                    </div>
                    <div class="col-6 text-right">
                      <button type="submit" class="btn btn-primary px-4" [disabled]="submitted">Login</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

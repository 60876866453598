import { registerLocaleData } from '@angular/common';
import localeBe from '@angular/common/locales/nl-BE';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DefaultLayoutComponent } from './containers';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var APP_CONTAINERS = [DefaultLayoutComponent];
registerLocaleData(localeBe);
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };

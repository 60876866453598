import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuditTrailService = /** @class */ (function () {
    function AuditTrailService(http) {
        this.http = http;
    }
    AuditTrailService.prototype.getSystemAuditTrails = function (auditTrailFilter) {
        return this.http.post(environment.api.url + "/admin/audit-trail/system", auditTrailFilter);
    };
    AuditTrailService.prototype.getSystemAuditTrailValues = function () {
        return this.http.get(environment.api.url + "/admin/audit-trail/values");
    };
    AuditTrailService.ngInjectableDef = i0.defineInjectable({ factory: function AuditTrailService_Factory() { return new AuditTrailService(i0.inject(i1.HttpClient)); }, token: AuditTrailService, providedIn: "root" });
    return AuditTrailService;
}());
export { AuditTrailService };

import { camelToSnake, snakeToCamel } from './string-utils';

export const renameKey = (o: Object, oldKey: string, newKey: string): void => {
  if (oldKey !== newKey) {
    Object.defineProperty(o, newKey, Object.getOwnPropertyDescriptor(o, oldKey));
    delete o[oldKey];
  }
};

export const camelToSnakeKeys = (o: Object, nested: boolean = true) => {
  if (o != null) {
    const allKeys = Object.keys(o);
    for (const key of allKeys) {
      if (o[key] && typeof o[key] === 'object') {
        camelToSnakeKeys(o[key]);
      }
      renameKey(o, key, camelToSnake(key));
    }
  }
};

export const snakeToCamelKeys = (o: Object, nested: boolean = true) => {
  if (o != null) {
    const allKeys = Object.keys(o);
    for (const key of allKeys) {
      if (o[key] && typeof o[key] === 'object') {
        snakeToCamelKeys(o[key]);
      }
      renameKey(o, key, snakeToCamel(key));
    }
  }
};

export const deepCopy = (o: any): any => {
  return JSON.parse(JSON.stringify(o));
};

export const isEmpty = (o: any): any => {
  for (const key in o) {
    if (o.hasOwnProperty(key)) return false;
  }
  return true;
};

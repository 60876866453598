export const environment = {
  production: false,
  api: {
    url: 'https://api-dev.roov.app'
  },
  view: {
    size: 20,
    default: {
      order: 'ASC'
    }
  },
  dateFormat: 'YYYY-MM-DD',
  firebase: {
    config: {
      apiKey: 'AIzaSyB1gF97lHo9g4i36F-WdRyco0p2Y_06q5A',
      authDomain: 'housepass-all.firebaseapp.com',
      databaseURL: 'https://housepass-all.firebaseio.com',
      projectId: 'housepass-all',
      storageBucket: 'housepass-all.appspot.com',
      messagingSenderId: '118380300445',
      appId: '1:118380300445:web:1dcb237566d873e61aac74',
      measurementId: 'G-GDKYRCMDGK'
    },
    maintenanceKey: 'maintenanceModeDEV'
  }
};

<app-header
  [fixed]="true"
  [navbarBrandFull]="{ src: 'assets/img/brand/logo.svg', height: 45, alt: 'roov Logo' }"
  [navbarBrandMinimized]="{ src: 'assets/img/brand/sygnet.png', width: 30, height: 30, alt: 'roov Logo' }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'"
>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        {{ profileService.name }}
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" href="#" (click)="onLogout()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body" *ngIf="navItems">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <breadcrumb [allowBootstrap]="true"></breadcrumb>
    </ol>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>
  <app-aside [fixed]="true" [display]="false"> </app-aside>
</div>
<app-footer>
  <span class="ml-auto"><a href="https://www.roov.app">roov</a> &copy; 2018 Augias corp.</span>
</app-footer>

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// local
import { AppEmail, AppEmailDto } from '../../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppEmailsService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<AppEmail[]> {
    return this.http
      .get<AppEmail[]>(`${environment.api.url}/admin/app-email`)
      .pipe(
        map(appEmails =>
          appEmails.sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime())
        )
      );
  }

  getById(appEmailId: string): Observable<AppEmail> {
    return this.http.get<AppEmail>(`${environment.api.url}/admin/app-email/${appEmailId}`);
  }

  getAllTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.url}/admin/app-email/types`);
  }

  getAvailableTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.url}/admin/app-email/types/available`);
  }

  createAppEmail(appEmailDto: AppEmailDto) {
    return this.http.post(`${environment.api.url}/admin/app-email`, appEmailDto);
  }

  updateAppEmail(appEmailId: string, appEmailDto: AppEmailDto): Observable<AppEmail> {
    return this.http.put<AppEmail>(`${environment.api.url}/admin/app-email/${appEmailId}`, appEmailDto);
  }
}

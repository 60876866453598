// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { PagingRequest, User, Statistics, UserList } from '../../models';
import { StringUtils } from '../../util';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getActiveUsersStats(): Observable<Statistics> {
    return this.http.get<Statistics>(`${environment.api.url}/admin/user/stats/active`);
  }

  getNotActiveUsersStats(): Observable<Statistics> {
    return this.http.get<Statistics>(`${environment.api.url}/admin/user/stats/not-active`);
  }

  getUsers(request: PagingRequest, query?: string): Observable<UserList> {
    if (StringUtils.isNotNullOrEmpty(query)) {
      query = `/${query}`;
    } else {
      query = '';
    }

    request.size = environment.view.size;

    return this.http.post<UserList>(`${environment.api.url}/admin/user/list${query}`, request);
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${environment.api.url}/admin/user/${id}`);
  }

  getRoles(id: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.url}/admin/user/roles/${id}`);
  }

  setRole(id: string, action: string, role: string): Observable<string[]> {
    return this.http.put<string[]>(`${environment.api.url}/admin/user/roles/${id}/${action}/${role}`, null);
  }

  downloadUserList(): Observable<Blob> {
    return this.http.get(`${environment.api.url}/admin/user/download/csv`, { responseType: 'blob' });
  }

  deleteImmediately(id): Observable<any> {
    return this.http.delete(`${environment.api.url}/admin/user/immediately/${id}`);
  }
}

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { AuditTrailFilter, AuditTrailResponse } from '../../models';

@Injectable({ providedIn: 'root' })
export class AuditTrailService {
  constructor(private http: HttpClient) {}

  getSystemAuditTrails(auditTrailFilter: AuditTrailFilter): Observable<AuditTrailResponse> {
    return this.http.post<AuditTrailResponse>(`${environment.api.url}/admin/audit-trail/system`, auditTrailFilter);
  }

  getSystemAuditTrailValues(): Observable<any> {
    return this.http.get<any>(`${environment.api.url}/admin/audit-trail/values`);
  }
}

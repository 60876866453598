// Angular
import { Validators } from '@angular/forms';
// local
import { numbersValidator, emailValidator, streetNumberValidator } from './custom-validators';

export const fieldsValidators = {
  email: [Validators.required, emailValidator],
  password: [Validators.required, Validators.minLength(6)],
  matchingPassword: [Validators.required, Validators.minLength(6)],
  firstName: [Validators.required],
  lastName: [Validators.required],
  pincode: [Validators.required, numbersValidator],
  repeatPincode: [Validators.required, numbersValidator],
  addressStreet: [Validators.required],
  addressNr: [Validators.required, streetNumberValidator],
  zipCodeOrCity: [Validators.required],
  country: [Validators.required]
};

export const camelToSnake = (camelString: string): string => {
  return camelString.replace(/([A-Z])/g, m => {
    return '_' + m.toLowerCase();
  });
};

export const snakeToCamel = (snakeString: string): string => {
  return snakeString.replace(/_\w/g, m => {
    return m[1].toUpperCase();
  });
};

export const StringUtils = {
  isNotNullOrEmpty: function(value: string): boolean {
    if (value != null && value != undefined && value != '') {
      return true;
    }
    return false;
  },

  isNullOrEmpty: function(value: string): boolean {
    return !StringUtils.isNotNullOrEmpty(value);
  }
};

// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// app
import { navItems } from '../../_nav';
import { ProfileService, ProfileStoreService } from '../../services/';
import { Subject, Subscription } from 'rxjs';
import { BreadcrumbsService, IBreadcrumb } from 'ng6-breadcrumbs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  private profileSubs: Subscription;

  constructor(
    public profileService: ProfileService,
    private profileStore: ProfileStoreService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.changes = new MutationObserver(() => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnInit() {
    this.profileSubs = this.profileStore.profileDataSubj.asObservable().subscribe(data => {
      if (data) {
        this.breadcrumbsService.breadcrumbsSource.next([]);
        this.breadcrumbsService.store([]);
        this.breadcrumbsService.get().subscribe(breadcrumbs => {
          breadcrumbs.forEach(breadcrumb => {
            if (breadcrumb.label === 'Home') {
              breadcrumb.url = '/dashboard';
            } else {
              breadcrumb.url = '/' + breadcrumb.label.toLowerCase();
            }
          });
        });
        this.navItems = navItems.filter(navItem => navItem.roles.some(role => data.roles.indexOf(role) > 0));
      }
    });
  }

  onLogout() {
    this.profileService.logout();
  }

  ngOnDestroy() {
    if (this.profileSubs) {
      this.profileSubs.unsubscribe();
    }
  }
}

import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ErrorService = /** @class */ (function () {
    function ErrorService(http) {
        this.http = http;
    }
    ErrorService.prototype.getErrors = function (errorFilterModel) {
        return this.http.post(environment.api.url + "/admin/errors/filter", errorFilterModel);
    };
    ErrorService.prototype.getErrorValues = function () {
        return this.http.get(environment.api.url + "/admin/errors/values");
    };
    ErrorService.prototype.setHandled = function (id, handled) {
        var path = handled ? 'handled' : 'unhandled';
        return this.http.put(environment.api.url + "/admin/errors/" + path + "/" + id, null);
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.inject(i1.HttpClient)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };

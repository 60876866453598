import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppEmailsService = /** @class */ (function () {
    function AppEmailsService(http) {
        this.http = http;
    }
    AppEmailsService.prototype.getAll = function () {
        return this.http
            .get(environment.api.url + "/admin/app-email")
            .pipe(map(function (appEmails) {
            return appEmails.sort(function (a, b) { return new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime(); });
        }));
    };
    AppEmailsService.prototype.getById = function (appEmailId) {
        return this.http.get(environment.api.url + "/admin/app-email/" + appEmailId);
    };
    AppEmailsService.prototype.getAllTypes = function () {
        return this.http.get(environment.api.url + "/admin/app-email/types");
    };
    AppEmailsService.prototype.getAvailableTypes = function () {
        return this.http.get(environment.api.url + "/admin/app-email/types/available");
    };
    AppEmailsService.prototype.createAppEmail = function (appEmailDto) {
        return this.http.post(environment.api.url + "/admin/app-email", appEmailDto);
    };
    AppEmailsService.prototype.updateAppEmail = function (appEmailId, appEmailDto) {
        return this.http.put(environment.api.url + "/admin/app-email/" + appEmailId, appEmailDto);
    };
    AppEmailsService.ngInjectableDef = i0.defineInjectable({ factory: function AppEmailsService_Factory() { return new AppEmailsService(i0.inject(i1.HttpClient)); }, token: AppEmailsService, providedIn: "root" });
    return AppEmailsService;
}());
export { AppEmailsService };

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3-rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { Form, Preview, Template } from '../../models';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(private http: HttpClient) {}

  getAllTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(`${environment.api.url}/admin/template`);
  }

  getLivePreview(templateId: string, forms: Form[]): Observable<Preview> {
    return this.http.post<Preview>(`${environment.api.url}/admin/template/preview/${templateId}`, forms);
  }

  getPlaceHolders(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.url}/admin/template/placeholders`);
  }
}

import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CampaignsService = /** @class */ (function () {
    function CampaignsService(http) {
        this.http = http;
    }
    CampaignsService.prototype.getAllCampaigns = function () {
        return this.http.get(environment.api.url + "/admin/campaign").pipe(map(function (campaigns) {
            return campaigns.sort(function (a, b) {
                return new Date(b.execution).getTime() - new Date(a.execution).getTime();
            });
        }));
    };
    CampaignsService.prototype.getTargetCount = function (filterDTO) {
        return this.http.post(environment.api.url + "/admin/campaign/target-count", filterDTO);
    };
    CampaignsService.prototype.createCampaign = function (campaignDTO) {
        return this.http.post(environment.api.url + "/admin/campaign/email", campaignDTO);
    };
    CampaignsService.prototype.createCampaignAllUsers = function (campaignDTO) {
        return this.http.post(environment.api.url + "/admin/campaign/users", campaignDTO);
    };
    CampaignsService.prototype.createCampaignAllUsersTargetCount = function () {
        return this.http.get(environment.api.url + "/admin/campaign/users/count");
    };
    CampaignsService.prototype.getPreview = function (campaignId) {
        return this.http.get(environment.api.url + "/admin/campaign/preview/" + campaignId);
    };
    CampaignsService.prototype.resendCampaign = function (campaignId) {
        return this.http.get(environment.api.url + "/admin/campaign/send/" + campaignId);
    };
    CampaignsService.ngInjectableDef = i0.defineInjectable({ factory: function CampaignsService_Factory() { return new CampaignsService(i0.inject(i1.HttpClient)); }, token: CampaignsService, providedIn: "root" });
    return CampaignsService;
}());
export { CampaignsService };

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// app
import { environment } from '../../../environments/environment';
import { Insight, InsightDTO, Metric, Type } from '../../models';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(private http: HttpClient) {}

  getAllMetrics(filterDto): Observable<Metric[]> {
    return this.http.post<Metric[]>(`${environment.api.url}/admin/analytics`, filterDto);
  }

  getListTypes(): Observable<Type[]> {
    return this.http
      .get<Type[]>(`${environment.api.url}/admin/analytics/types`)
      .pipe(
        map(types =>
          types.filter(type => !type.isDisabled).sort((a, b) => a.insightLabel.localeCompare(b.insightLabel))
        )
      );
  }

  getInsights(insightDTO: InsightDTO): Observable<Insight> {
    return this.http.post<Insight>(`${environment.api.url}/admin/analytics/insights`, insightDTO);
  }
}

import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WalletService = /** @class */ (function () {
    function WalletService(http) {
        this.http = http;
    }
    WalletService.prototype.getWallets = function (userId) {
        return this.http.get(environment.api.url + "/admin/wallet/list/" + userId);
    };
    WalletService.prototype.getWallet = function (walletId) {
        return this.http.get(environment.api.url + "/admin/wallet/" + walletId);
    };
    WalletService.prototype.findLocalities = function (query, countryCode, language) {
        var search = {
            query: query,
            countryCode: countryCode,
            languageCode: language
        };
        return this.http.post(environment.api.url + "/municipalities/search", search);
    };
    WalletService.prototype.updateLocality = function (walletId, dto) {
        return this.http.post(environment.api.url + "/admin/wallet/locality/" + walletId, dto);
    };
    WalletService.ngInjectableDef = i0.defineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.inject(i1.HttpClient)); }, token: WalletService, providedIn: "root" });
    return WalletService;
}());
export { WalletService };

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { RoovErrorFilter, RoovError, RoovErrorResponse } from '../../models';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private http: HttpClient) {}

  getErrors(errorFilterModel: RoovErrorFilter): Observable<RoovErrorResponse> {
    return this.http.post<RoovErrorResponse>(`${environment.api.url}/admin/errors/filter`, errorFilterModel);
  }

  getErrorValues(): Observable<any> {
    return this.http.get<any>(`${environment.api.url}/admin/errors/values`);
  }

  setHandled(id: string, handled: boolean): Observable<RoovError> {
    const path = handled ? 'handled' : 'unhandled';

    return this.http.put<RoovError>(`${environment.api.url}/admin/errors/${path}/${id}`, null);
  }
}

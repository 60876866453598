// Angular
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// app
import { LoginDTO, User } from '../../models';
import { AuthService, ProfileService, ProfileStoreService } from '../../services';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  errorLabel = '';
  loginFormGroup: FormGroup;
  loginData: LoginDTO;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    private profileStore: ProfileStoreService
  ) {}

  ngOnInit(): void {
    this.loginData = {
      email: '',
      password: ''
    };

    this.loginFormGroup = this.formBuilder.group({
      email: new FormControl(this.loginData.email, [Validators.required, Validators.email]),
      password: new FormControl(this.loginData.password, [Validators.required])
    });
  }

  onSubmit() {
    this.submitted = true;

    this.authService.login(this.loginFormGroup.value).subscribe(
      data => {
        this.submitted = false;
        if ((data as User) != null) {
          this.profileService.setProfile(data);
          localStorage.setItem(
            'ROLE_ADMIN',
            data.roles.findIndex(role => role === 'ROLE_ADMIN') > 0 ? 'true' : 'false'
          );
          this.profileStore.setProfileData(data);
          this.errorLabel = '';
          this.router.navigate(['']);
        } else {
          this.errorLabel = 'The login has failed';
        }
      },
      error => {
        this.submitted = false;

        if ((error as any) instanceof HttpErrorResponse) {
          if ((error as any).status === 497) {
            this.errorLabel = 'The user is not activated';
            return;
          } else if ((error as any).status === 401) {
            this.errorLabel = 'The password is not correct';
            return;
          } else if ((error as any).status === 400) {
            this.errorLabel = 'The user is not found';
            return;
          } else if ((error as any).status === 496) {
            this.errorLabel = 'The user was already deleted';
            return;
          }
        }
        this.errorLabel = 'The login has failed';
      }
    );
  }
}

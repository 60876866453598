import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(http) {
        this.http = http;
    }
    AnalyticsService.prototype.getAllMetrics = function (filterDto) {
        return this.http.post(environment.api.url + "/admin/analytics", filterDto);
    };
    AnalyticsService.prototype.getListTypes = function () {
        return this.http
            .get(environment.api.url + "/admin/analytics/types")
            .pipe(map(function (types) {
            return types.filter(function (type) { return !type.isDisabled; }).sort(function (a, b) { return a.insightLabel.localeCompare(b.insightLabel); });
        }));
    };
    AnalyticsService.prototype.getInsights = function (insightDTO) {
        return this.http.post(environment.api.url + "/admin/analytics/insights", insightDTO);
    };
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.HttpClient)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };

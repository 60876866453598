// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// local
import { ProfileStoreService } from './profile-store.service';

@Injectable({ providedIn: 'root' })
export class AdminGuardService implements CanActivate {
  constructor(private profileStore: ProfileStoreService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('ROLE_ADMIN') === 'true') {
      return true;
    } else {
      return this.router.parseUrl('/dashboard');
    }
  }
}

// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// app
import { DefaultLayoutComponent } from './containers';
// views
import { AdminGuardService } from './services';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      breadcrumb: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      breadcrumb: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      breadcrumb: 'Home'
    },
    children: [
      {
        path: 'marketing',
        loadChildren: './views/marketing/marketing.module#MarketingModule'
      },
      {
        path: 'logs',
        canActivate: [AdminGuardService],
        loadChildren: './views/logs/logs.module#LogsModule'
      },
      {
        path: 'administration',
        canActivate: [AdminGuardService],
        loadChildren: './views/administration/administration.module#AdministrationModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

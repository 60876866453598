import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TemplateService = /** @class */ (function () {
    function TemplateService(http) {
        this.http = http;
    }
    TemplateService.prototype.getAllTemplates = function () {
        return this.http.get(environment.api.url + "/admin/template");
    };
    TemplateService.prototype.getLivePreview = function (templateId, forms) {
        return this.http.post(environment.api.url + "/admin/template/preview/" + templateId, forms);
    };
    TemplateService.prototype.getPlaceHolders = function () {
        return this.http.get(environment.api.url + "/admin/template/placeholders");
    };
    TemplateService.ngInjectableDef = i0.defineInjectable({ factory: function TemplateService_Factory() { return new TemplateService(i0.inject(i1.HttpClient)); }, token: TemplateService, providedIn: "root" });
    return TemplateService;
}());
export { TemplateService };

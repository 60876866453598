// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3-rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { Campaign, InsightDTO } from '../../models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CampaignsService {
  constructor(private http: HttpClient) {}

  getAllCampaigns(): Observable<Campaign[]> {
    return this.http.get<Campaign[]>(`${environment.api.url}/admin/campaign`).pipe(
      map(campaigns => {
        return campaigns.sort((a, b) => {
          return new Date(b.execution).getTime() - new Date(a.execution).getTime();
        });
      })
    );
  }

  getTargetCount(filterDTO: InsightDTO): Observable<number> {
    return this.http.post<number>(`${environment.api.url}/admin/campaign/target-count`, filterDTO);
  }

  createCampaign(campaignDTO) {
    return this.http.post(`${environment.api.url}/admin/campaign/email`, campaignDTO);
  }

  createCampaignAllUsers(campaignDTO) {
    return this.http.post(`${environment.api.url}/admin/campaign/users`, campaignDTO);
  }

  createCampaignAllUsersTargetCount(): Observable<number> {
    return this.http.get<number>(`${environment.api.url}/admin/campaign/users/count`);
  }

  getPreview(campaignId) {
    return this.http.get(`${environment.api.url}/admin/campaign/preview/${campaignId}`);
  }

  resendCampaign(campaignId) {
    return this.http.get(`${environment.api.url}/admin/campaign/send/${campaignId}`);
  }
}

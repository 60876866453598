export const navItems = [
  {
    title: true,
    name: 'Dashboard',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    title: true,
    name: 'Marketing',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    name: 'Analytics',
    url: '/marketing/analytics',
    icon: 'icon-graph',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    name: 'Campaigns',
    url: '/marketing/campaigns',
    icon: 'icon-speech',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    name: 'App emails',
    url: '/marketing/app-emails',
    icon: 'icon-envelope-open',
    roles: ['ROLE_ADMIN', 'ROLE_MARKETING']
  },
  {
    title: true,
    name: 'Logs',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'Audit Trail',
    url: '/logs/audit-trail',
    icon: 'icon-book-open',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'Errors',
    url: '/logs/errors',
    icon: 'icon-fire',
    roles: ['ROLE_ADMIN']
  },
  /*  {
    name: 'User Stats',
    url: '/analytics/user-stats',
    icon: 'icon-people'
  },*/
  {
    title: true,
    name: 'Administration',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'Users',
    url: '/administration/users',
    icon: 'icon-people',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'MP Registrations',
    url: '/administration/mp-registrations',
    icon: 'icon-user-follow',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'MP Requests',
    url: '/administration/mp-requests',
    icon: 'icon-docs',
    roles: ['ROLE_ADMIN']
  }
];

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { User } from '../../models';
import { ProfileStoreService } from './profile-store.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private LOCAL_STORAGE_TOKEN_KEY = 'user-token';
  private token: string;
  private profile: User;
  name = '';
  id = '';

  constructor(private http: HttpClient, private router: Router, private profileStore: ProfileStoreService) {}

  setProfile(profile: User): void {
    this.profile = profile;
    if (this.profile.firstName != null && this.profile.lastName != null) {
      this.name = this.profile.firstName + ' ' + this.profile.lastName;
    }
    this.id = this.profile.id;
  }

  getProfile(): Observable<User> {
    return this.http.get<User>(`${environment.api.url}/admin/profile`);
  }

  logout(): void {
    this.token = null;
    window.localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, null);
    this.router.navigate(['login']);
  }

  public getToken(url?: string): string {
    if (url && url.indexOf('/login') > -1) {
      return '';
    }
    if (!this.token) {
      this.token = window.localStorage.getItem(this.LOCAL_STORAGE_TOKEN_KEY);

      if (!this.token) {
        this.router.navigate(['login']);
      }
    }
    return this.token;
  }

  public setToken(token: string) {
    this.token = token;
    window.localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, this.token);
  }
}

// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { BehaviorSubject } from 'rxjs';
// local
import { User } from '../../models';

@Injectable({ providedIn: 'root' })
export class ProfileStoreService {
  profileDataSubj = new BehaviorSubject<User>(undefined);

  setProfileData(user: User): void {
    this.profileDataSubj.next(user);
  }
}

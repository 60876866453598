// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// app
import { User } from '../models';
import { ProfileService } from '../services';
import { camelToSnakeKeys, snakeToCamelKeys } from '../util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(public router: Router, private profileService: ProfileService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `${this.profileService.getToken(request.url)}`
      },
      body: camelToSnakeKeys(request.body)
    });

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.token && event.body.token.length) {
              this.profileService.setToken((event.body as User).token);
            }
            snakeToCamelKeys(event.body);
            return event;
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 0 || err.status === 401 || err.status === 498 || err.status === 499) {
              this.router.navigate(['login']);
            }
          }
        }
      )
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// app
import { environment } from '../../../environments/environment';
import { ProfileStoreService } from './profile-store.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./profile-store.service";
var ProfileService = /** @class */ (function () {
    function ProfileService(http, router, profileStore) {
        this.http = http;
        this.router = router;
        this.profileStore = profileStore;
        this.LOCAL_STORAGE_TOKEN_KEY = 'user-token';
        this.name = '';
        this.id = '';
    }
    ProfileService.prototype.setProfile = function (profile) {
        this.profile = profile;
        if (this.profile.firstName != null && this.profile.lastName != null) {
            this.name = this.profile.firstName + ' ' + this.profile.lastName;
        }
        this.id = this.profile.id;
    };
    ProfileService.prototype.getProfile = function () {
        return this.http.get(environment.api.url + "/admin/profile");
    };
    ProfileService.prototype.logout = function () {
        this.token = null;
        window.localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, null);
        this.router.navigate(['login']);
    };
    ProfileService.prototype.getToken = function (url) {
        if (url && url.indexOf('/login') > -1) {
            return '';
        }
        if (!this.token) {
            this.token = window.localStorage.getItem(this.LOCAL_STORAGE_TOKEN_KEY);
            if (!this.token) {
                this.router.navigate(['login']);
            }
        }
        return this.token;
    };
    ProfileService.prototype.setToken = function (token) {
        this.token = token;
        window.localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, this.token);
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.ProfileStoreService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };

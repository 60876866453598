import { HttpClient } from '@angular/common/http';
// app
import { environment } from '../../../environments/environment';
import { StringUtils } from '../../util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.getActiveUsersStats = function () {
        return this.http.get(environment.api.url + "/admin/user/stats/active");
    };
    UserService.prototype.getNotActiveUsersStats = function () {
        return this.http.get(environment.api.url + "/admin/user/stats/not-active");
    };
    UserService.prototype.getUsers = function (request, query) {
        if (StringUtils.isNotNullOrEmpty(query)) {
            query = "/" + query;
        }
        else {
            query = '';
        }
        request.size = environment.view.size;
        return this.http.post(environment.api.url + "/admin/user/list" + query, request);
    };
    UserService.prototype.getUser = function (id) {
        return this.http.get(environment.api.url + "/admin/user/" + id);
    };
    UserService.prototype.getRoles = function (id) {
        return this.http.get(environment.api.url + "/admin/user/roles/" + id);
    };
    UserService.prototype.setRole = function (id, action, role) {
        return this.http.put(environment.api.url + "/admin/user/roles/" + id + "/" + action + "/" + role, null);
    };
    UserService.prototype.downloadUserList = function () {
        return this.http.get(environment.api.url + "/admin/user/download/csv", { responseType: 'blob' });
    };
    UserService.prototype.deleteImmediately = function (id) {
        return this.http.delete(environment.api.url + "/admin/user/immediately/" + id);
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };

import { Router } from '@angular/router';
// local
import { ProfileStoreService } from './profile-store.service';
import * as i0 from "@angular/core";
import * as i1 from "./profile-store.service";
import * as i2 from "@angular/router";
var AdminGuardService = /** @class */ (function () {
    function AdminGuardService(profileStore, router) {
        this.profileStore = profileStore;
        this.router = router;
    }
    AdminGuardService.prototype.canActivate = function (route, state) {
        if (localStorage.getItem('ROLE_ADMIN') === 'true') {
            return true;
        }
        else {
            return this.router.parseUrl('/dashboard');
        }
    };
    AdminGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AdminGuardService_Factory() { return new AdminGuardService(i0.inject(i1.ProfileStoreService), i0.inject(i2.Router)); }, token: AdminGuardService, providedIn: "root" });
    return AdminGuardService;
}());
export { AdminGuardService };

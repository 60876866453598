// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// 3rd party
import { Observable } from 'rxjs';
// app
import { environment } from '../../../environments/environment';
import { LocalityChange, LocalitySearch, LocalitySearchRequest, Wallet } from '../../models';

@Injectable({ providedIn: 'root' })
export class WalletService {
  constructor(private http: HttpClient) {}

  getWallets(userId: string): Observable<Wallet[]> {
    return this.http.get<Wallet[]>(`${environment.api.url}/admin/wallet/list/${userId}`);
  }

  getWallet(walletId: string): Observable<Wallet> {
    return this.http.get<Wallet>(`${environment.api.url}/admin/wallet/${walletId}`);
  }

  findLocalities(query: string, countryCode: string, language: string): Observable<LocalitySearch[]> {
    const search: LocalitySearchRequest = {
      query: query,
      countryCode: countryCode,
      languageCode: language
    };

    return this.http.post<LocalitySearch[]>(`${environment.api.url}/municipalities/search`, search);
  }

  updateLocality(walletId: string, dto: LocalityChange): Observable<Wallet> {
    return this.http.post<Wallet>(`${environment.api.url}/admin/wallet/locality/${walletId}`, dto);
  }
}

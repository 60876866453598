export const sameArrays = (arr1: any[], arr2: any[]): boolean => JSON.stringify(arr1) === JSON.stringify(arr2);

export const orderArrayOfObjectsByKey = (array: any[], key: string): void => {
  array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const findDifferencesTwoArrays = (arr1, arr2) => ({
  added: findFirstArrayAddedItems(arr1, arr2),
  removed: findFirstArrayAddedItems(arr2, arr1)
});

const findFirstArrayAddedItems = (arr1, arr2) => {
  const differences = [];
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (JSON.stringify(arr1[i]) === JSON.stringify(arr2[j])) {
        // exists, nothing to do
        j = arr2.length;
      } else {
        if (j === arr2.length - 1) {
          // was added to arr1
          differences.push(arr1[i]);
        }
      }
    }
  }
  return differences;
};

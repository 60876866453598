// 3rd-party
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ProfileStoreService = /** @class */ (function () {
    function ProfileStoreService() {
        this.profileDataSubj = new BehaviorSubject(undefined);
    }
    ProfileStoreService.prototype.setProfileData = function (user) {
        this.profileDataSubj.next(user);
    };
    ProfileStoreService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileStoreService_Factory() { return new ProfileStoreService(); }, token: ProfileStoreService, providedIn: "root" });
    return ProfileStoreService;
}());
export { ProfileStoreService };
